import React from 'react'
import { compose } from 'recompose'
import { StripeProvider, Elements } from 'react-stripe-elements'

import { withAuthorization } from '../components/session'
import { withFirebase } from '../components/firebase'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import BillingUpdateForm from '../components/account/billing-update-form'

const stripeKeys = {
  US: process.env.GATSBY_STRIPE_PUBLIC_KEY_US,
  UK: process.env.GATSBY_STRIPE_PUBLIC_KEY_UK,
  AU: process.env.GATSBY_STRIPE_PUBLIC_KEY_AU,
}

const AccountBilling = ({ location, authUser, firebase }) => {
  const backUrl =
    location.state && location.state.prevPath ? location.state.prevPath : '/'

  let body
  if (authUser && authUser.stripe && authUser.stripe.country) {
    const currStripeKey = stripeKeys[authUser.stripe.country.toUpperCase()]
    if (currStripeKey) {
      body = (
        <StripeProvider apiKey={currStripeKey} key={currStripeKey}>
          <Elements>
            <BillingUpdateForm user={authUser} firebase={firebase} />
          </Elements>
        </StripeProvider>
      )
    }
  }

  if (body === null) {
    body = <p>It looks like your account is not yet on a plan.</p>
  }

  return (
    <Wrap>
      <SEO title="Update Billing" />
      <FocusHeader
        title="Account"
        subTitle="Update Billing"
        titleLink="/account"
        backUrl={backUrl}
      />
      <FocusBody>
        <Title>Update Billing</Title>
        {body}
      </FocusBody>
    </Wrap>
  )
}

const condition = authUser => !!authUser
const AccountWithAuth = compose(withAuthorization(condition))(AccountBilling)
const AccountWithFirebase = withFirebase(AccountWithAuth)
export default AccountWithFirebase

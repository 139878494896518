import React from 'react'

export default () => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.19712 4.8C1.19712 2.14903 3.34615 0 5.99712 0C8.64808 0 10.7971 2.14903 10.7971 4.8V6.09132C11.4963 6.32596 12 6.98651 12 7.76471V16.2353C12 17.2099 11.2099 18 10.2353 18H1.76471C0.790085 18 0 17.2099 0 16.2353V7.76471C0 6.98865 0.500946 6.32959 1.19712 6.09326V4.8ZM8.39711 4.8V6H3.59712V4.8C3.59712 3.47452 4.67163 2.4 5.99712 2.4C7.3226 2.4 8.39711 3.47452 8.39711 4.8ZM6.0024 13.2019C7.10697 13.2019 8.0024 12.3065 8.0024 11.2019C8.0024 10.0974 7.10697 9.20192 6.0024 9.20192C4.89783 9.20192 4.0024 10.0974 4.0024 11.2019C4.0024 12.3065 4.89783 13.2019 6.0024 13.2019Z"
        fill="#ffffff"
      />
    </svg>
  )
}

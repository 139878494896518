import React from 'react'
import { CardElement, injectStripe, Elements } from 'react-stripe-elements'
import { FormBox, FormFields, FormField, FormCopy } from '../page/components'
import styled from 'styled-components'
import useWindowSize from '@rehooks/window-size'
import _ from 'lodash'

const elementsOptions = {
  fonts: [
    {
      cssSrc: 'https://use.typekit.net/hxu6eip.css',
    },
  ],
}

const SetupPlanCc = ({
  stripe,
  formData,
  stripeState,
  onStripePaymentMethod,
  handleDataChange,
  title,
}) => {
  const windowSize = useWindowSize()

  return (
    <FormBox>
      <Elements {...elementsOptions}>
        <CardForm
          stripe={stripe}
          formData={formData}
          stripeState={stripeState}
          handleDataChange={handleDataChange}
          onStripePaymentMethod={onStripePaymentMethod}
          windowSize={windowSize}
          title={title}
        />
      </Elements>
    </FormBox>
  )
}

class _CardForm extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    // console.log(
    //   'yolo',
    //   this.props.stripeState.fetching,
    //   this.props.stripeState.fetched,
    //   prevProps.stripeState.fetching
    // )
    if (
      this.props.stripeState.fetching === true &&
      this.props.stripeState.fetched === false &&
      prevProps.stripeState.fetching !== true
    ) {
      this.handleSubmit()
    }
  }

  handleSubmit = async () => {
    if (this.props.stripe) {
      // Make a payment method and send it to setup-form-wrap
      console.log('✔ Make stripe payment method')
      let {
        paymentMethod,
        error,
      } = await this.props.stripe.createPaymentMethod('card', CardElement, {
        billing_details: {
          email: this.props.formData.user.email,
        },
      })
      console.log('paymentMethod', paymentMethod)
      this.props.onStripePaymentMethod(paymentMethod, error)
    } else {
      console.log(':( Stripe.js not loaded')
    }
  }

  render() {
    const { handleDataChange, formData, windowSize, title } = this.props
    const handleBlur = () => {
      // console.log('[blur]')
    }
    const handleChange = change => {
      // console.log('[change]', change)
      const user = _.cloneDeep(formData.user) || {}
      if (change.complete === true) {
        user['stripeCC'] = true
      } else {
        user['stripeCC'] = false
      }
      handleDataChange('user', user)
    }
    const handleFocus = () => {
      // console.log('[focus]')
    }
    const handleReady = () => {
      // console.log('[ready]')
    }
    const elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    }

    let fontSize = `50px`
    if (windowSize) {
      if (windowSize.innerWidth <= 900) {
        fontSize = '36px'
      } else if (windowSize.innerWidth <= 1600) {
        fontSize = '36px'
      }
    }

    const createOptions = (fontSize, padding) => {
      return {
        style: {
          base: {
            fontSize: fontSize,
            fontWeight: 'bold',
            color: '#fff',
            fontFamily: "'ars-maquette-web', monospace",
            '::placeholder': {
              color: '#999',
            },
            padding,
          },
          invalid: {
            color: '#F7403a',
          },
        },
        classes: elementClasses,
      }
    }

    let titleContent
    if (title) {
      titleContent = (
        <FormField width={`100%`}>
          <FormCopy>
            <p>{title}</p>
          </FormCopy>
        </FormField>
      )
    }

    return (
      <StripeEl>
        <FormFields>
          {titleContent}
          <FormField width={`100%`}>
            <CardElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(fontSize)}
            />
          </FormField>
        </FormFields>
      </StripeEl>
    )
  }
}

const CardForm = injectStripe(_CardForm)
export default SetupPlanCc

const StripeEl = styled.div`
  .empty,
  .invalid,
  .focused {
    padding-bottom: 12px;
  }

  .StripeElement--complete {
    border-bottom: 3px solid ${props => props.theme.colors.white};
    padding-bottom: 12px;
  }

  .empty {
    border-bottom: 3px solid ${props => props.theme.colors.white};
  }
  .focused {
    border-bottom: 3px solid ${props => props.theme.colors.highlight1};
  }
  .invalid {
    border-bottom: 3px solid ${props => props.theme.colors.error};
  }
`

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import * as Yup from 'yup'
import styled from 'styled-components'
import { injectStripe } from 'react-stripe-elements'
import { Flex, Box } from 'reflexbox'
import SetupPlanCc from './setup-plan-cc'
import {
  FormBox,
  FormFields,
  Button,
  ButtonsWrap,
  Errors,
  FormCopy,
} from '../page/components'
import IconLock from '../svg/icon-lock'

const INITIAL_STRIPE_STATE = {
  fetching: false,
  fetched: false,
  payment: null,
  paymentError: null,
}

const userSchema = Yup.object().shape({
  stripeCC: Yup.bool().oneOf(
    [true],
    'Please check or enter your credit card details.'
  ),
})

const Form = ({ stripe, user, firebase }) => {
  const [submitting, setSubmitting] = useState()
  const [updated, setUpdated] = useState(false)
  const [errors, setErrors] = useState([])
  const [stripeState, setStripeState] = useState(INITIAL_STRIPE_STATE)

  const formData = {
    user,
  }

  useEffect(
    () => {
      if (
        stripeState.fetched === true &&
        stripeState.fetching === false &&
        submitting === true
      ) {
        // If Stripe has fetched:
        // - Meaning createPaymentMethod called with CC details and response received.
        // - Then we can call the main submit
        onSubmit(true)
      }
    },
    [stripeState, submitting]
  )

  const onStripePaymentMethod = (paymentMethod, paymentMethodError) => {
    if (paymentMethodError) {
      setSubmitting(false)
      setErrors([`${paymentMethodError.message}`])
    }
    setStripeState(prevState => ({
      ...prevState,
      fetched: true,
      fetching: false,
      payment: paymentMethod,
      paymentError: paymentMethodError,
    }))
  }

  const onSubmit = canContinue => {
    if (submitting && !canContinue) return
    setSubmitting(true)
    setErrors([])

    const userValid = userSchema.validate(formData.user)

    Promise.all([userValid])
      .then(() => {
        if (stripeState.fetched && stripeState.payment) {
          const stripeUpdatePaymentMethodFunc = firebase.functions.httpsCallable(
            'stripeUpdatePaymentMethod'
          )
          const funcData = {
            user: formData.user,
            stripe: stripeState.payment,
          }
          console.log('✔ Ready to process')
          stripeUpdatePaymentMethodFunc(funcData)
            .then(res => {
              console.log('✔ Billing updated', res)
              setSubmitting(false)
              setUpdated(true)
            })
            .catch(error => {
              const { message } = error
              setStripeState(INITIAL_STRIPE_STATE)
              setSubmitting(false)
              setErrors([`We had a problem submitting! ${message}`])
            })
        } else {
          setStripeState(prevState => ({
            ...prevState,
            fetched: false,
            fetching: true,
            payment: null,
            paymentError: null,
          }))
        }
      })
      .catch(err => {
        console.log('✘ Error', err)
        setSubmitting(false)
      })
  }

  let errorContent
  if (errors.length > 0) {
    errorContent = (
      <Flex flexWrap={['wrap']} width={`100%`} mb={[0]}>
        <Box width={[1, 1]}>
          <Errors>
            {errors.map((error, i) => {
              return <p key={`error-${i}`}>{error}</p>
            })}
          </Errors>
        </Box>
      </Flex>
    )
  }

  const buttonText = submitting ? `Processing...` : `Submit`

  const submitContent = (
    <>
      {errorContent}
      <SubmitWrap isSubmitting={submitting}>
        <ButtonsWrap>
          <Button type="submit" onClick={() => onSubmit()}>
            <ButtonIcon>
              <IconLock />
            </ButtonIcon>{' '}
            {buttonText}
          </Button>
        </ButtonsWrap>
      </SubmitWrap>
    </>
  )

  if (updated) {
    return (
      <FormBox>
        <FormFields>
          <FormCopy>
            <p>
              Your billing details have successfully been updated. Back to{' '}
              <Link to="/account">Account.</Link>
            </p>
          </FormCopy>
        </FormFields>
      </FormBox>
    )
  } else {
    return (
      <FormBox>
        <FormFields>
          <FormCopy>
            <p>
              Please enter your new credit card details below.
              <br />
              <br />
            </p>
          </FormCopy>
          <CCWrap>
            <SetupPlanCc
              stripe={stripe}
              formData={formData}
              stripeState={stripeState}
              handleDataChange={() => {
                return
              }}
              onStripePaymentMethod={onStripePaymentMethod}
            />
          </CCWrap>
          {submitContent}
        </FormFields>
      </FormBox>
    )
  }
}

const UpdateBillingForm = injectStripe(Form)
export default UpdateBillingForm

const ButtonIcon = styled.span`
  display: inline-block;
  margin-right: 10px;
`

const CCWrap = styled.div`
  width: 100%;
  display: block;
`

const SubmitWrap = styled.div`
  margin-top: 2em;
  width: 100%;
  opacity: ${props => (props.isSubmitting ? `0.5` : `1.0`)};
`
